<template>
  <CRow>
    <CCol col="12" xl="7">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item navbar-custom" v-for="(site, index) in siteTables" v-if='site.data && index<=site_tab_limit'>
          <a tab class="nav-link" :class="{active:index==active_site_index}" data-toggle="tab" :id="'#site' + index" :href="'#site_tab' + index" @click="onClickTab(index)" role="tab">{{site.title}}</a>
        </li>
        <div style="text-align: right; margin-top: -5px;" v-show="siteTables.length>site_tab_limit">
          <CDropdown color="primary" :toggler-text="site_name" class="m-2" ref="dropdown">
            <li v-for="(site, index) in siteTables" :key="site.title" v-if='site.data && index>site_tab_limit'>
              <CDropdownItem :class="{active:index==active_site_index}" data-toggle="tab" :id="'#site' + index" :href="'#site_tab' + index" @click="onClickTab(index)" role="tab">{{site.title}}</CDropdownItem>        
            </li>
          </CDropdown>
        </div>        
      </ul>

      <div class="tab-content">
        <div class="tab-pane" v-for="(site, index) in siteTables" :id="'site_tab' + index"  :key="site.title" :class='{active:index==active_site_index}' role="tabpanel">
          <div class="card-body">
            <v-table :id="'dataTable'+index" :columns="site.columns" :data="site.data" :options="site.options" @on-click-row="onClickRow"></v-table>
          </div>
        </div>
      </div>
    </CCol>
    <CCol col="12" xl="5">
      <GoogleMap ref="googleMap" style="height:100%;padding-top:1.0%;padding-bottom:1.0%;padding-reft:1.0%;padding-right:1.0%" />
    </CCol>
  </CRow>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import EventBus from '@/services/EventBus'
import utils from '@/services/utils';
import CronParser from 'cron-parser'

import GoogleMap from '@/views/dashboard/GoogleMap'

import {
  DEVICE_TABLE_OPTIOINS, DEVICE_TABLE_COLUMS,  LEAF_TYPES
} from "@/constants/deviceSettings";

export default {
  name: 'Device',
  components: {
    GoogleMap
  },
  props: {
    prefix: {
      type: String,
      default: ''
    }
  },
  created: function() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;
    this.sites = this.userInfo.sites

    this.active_site_index = _.get(this.user, "active_site_index", 0)

    this.getDashboard()
    EventBus.$on('reload', this.getDashboard)
  },
  mounted() {
    $('#dataTable1').on('all.bs.table', function(e, row, $element) {
      console.log('all.bs.table e:', e)
    });
  },
  beforeDestroy(){
    EventBus.$off('reload', this.getDashboard)
  },
  data () {
    return {
      site_name: '설치 사이트',
      site_tab_limit: this.$is_mobile ? 4 : 16,
      active_site_index: 0,
      siteTables: [],
      dashboard: {},
      sensor_type: utils.getSensorTypes()
    }
  },
  methods: {
    makeTable(site_guids) {
      if (site_guids.length === this.siteTables.length) {
        return;
      }
      this.siteTables = [];
      for (var i = 0; i < site_guids.length; i++) {
        var table = {
          options: DEVICE_TABLE_OPTIOINS,
          columns: DEVICE_TABLE_COLUMS,
          title: '',
          data: []
        }
        _.find(table.columns, { field: 'site' }).formatter = this.siteFormatter;
        _.find(table.columns, { field: 'name' }).formatter = this.nameFormatter;
        _.find(table.columns, { field: 'sensor' }).formatter = this.sensorFormatter;
        _.find(table.columns, { field: 'watering' }).formatter = this.wateringFormatter;
        _.find(table.columns, { field: 'nutrient' }).formatter = this.nutrientFormatter;
        _.find(table.columns, { field: 'sensor_time' }).formatter = this.dateFormatter;
        _.find(table.columns, { field: 'prev_time' }).formatter = this.dateFormatter2;
        this.siteTables.push(table)
      }
    },
    getDeviceUrl(row) {
      return '#/{0}device/{1}'.format(this.prefix, row.guid);
    },
    siteFormatter(value, row, index) {
      return "<a href='#/{0}sites/{1}'>{2}</a>".format(this.prefix, row.site_guid, value);
    },
    nameFormatter(value, row, index) {
      return "<a href='#/{0}device/{1}'>{2}</a>".format(this.prefix, row.guid, row.name);
    },
    imageFormatter(value, row, index) {
      if (_.isUndefined(value) || value.length < 1)
        return '';

      var images = []
      var device_url = this.getDeviceUrl(row);
      value =  _.sortBy(value, ['created_at']).reverse();
      for (var i = 0; i < value.length; i++) {
        var el = value[i]
        if (_.isUndefined(el.storage)) {
          continue
        }
        var url = loopback.defaults.baseURL + '/' + el.storage + 's/' + el.device_guid + '/download/' + el.file_name;
        var label = el.predict.label
        images.push({
          url: url,
          label: LEAF_TYPES[label] || label,
          created_at: el.created_at
        })
      }

      var target_id = 'img_' + row.guid
      var result = '<div id="' + target_id + '" class="carousel slide" data-ride="carousel" data-interval="5000">' +
        '<ol class="carousel-indicators hidden">'
      for (var i = 0; i < images.length; i++) {
        var elem = '<li data-target="#{0}" data-slide-to="{1}" {2}></li>'
        result += elem.format(target_id, i, i == 0 ? 'class="active"' : '')
      }
      result += '</ol>'
      result += '<div class="carousel-inner" >'
      for (var i = 0; i < images.length; i++) {
        var item = '<div class="carousel-item {0}">'
        var img = '<a href="{0}"><img src="{1}" alt="" style="width: 300px; height: 250px; display: block; margin: 0px auto;"></a>'
        var caption = '<div class="carousel-caption"><p>{0}<br>{1}</p></div></div>'
        result += item.format(i == 0 ? 'active' : '')
        result += img.format(device_url, images[i].url)
        result += caption.format(images[i].label, moment(images[i].created_at).format("DD일 HH시 mm분"))
      }
      result += '</div>'

      result += '<a class="carousel-control-prev" href="#' + target_id + '" role="button" data-slide="prev">'
      result += '<span class="carousel-control-prev-icon" aria-hidden="true"></span>'
      result += '<span class="sr-only">Previous</span>'
      result += '</a>'
      result += '<a class="carousel-control-next" href="#' + target_id + '" role="button" data-slide="next">'
      result += '<span class="carousel-control-next-icon" aria-hidden="true"></span>'
      result += '<span class="sr-only">Next</span>'
      result += '</a>'
      result += '</div>'

      result += '<style scoped>'
      result += '.carousel-indicators {'
      result += '  bottom: -25px;'
      result += '  z-index: 10;'
      result += '}'
      result += '.carousel-inner {'
      result += '  width: 100%;'
      result += '  max-height: 256px !important;'
      result += '  background-color: silver !important;'
      result += '}'
      result += '.carousel-caption {'
      result += '  bottom: -10px;'
      result += '  z-index: 10;'
      result += '  padding-top: 20px;'
      result += '  padding-bottom: 0px;'
      result += '}'
      result += '</style>'
      // console.log('[Device Carousel html] : ', result)
      return result;
    },
    sensorFormatter(value, row, index) {
      var self = this;
      var result = "";
      if (row.service_type === 'sis.leaf') {
        return self.imageFormatter(row.images, row, index);
      }
      value.data = _.sortBy(value.data, ['type']);
      value.data.forEach(function(v) {
        if (!_.get(row, "empty", false)) {
          // temporary adjust : must be deleted
          if (v.id === 0) v.id = 1;
          // temporary adjust : must be deleted

          /*var constraints = {
            sensor_type: v.type % 64
          }
          if (v.type !== 6) {
            constraints["sensor_id"] = v.id
          }
          var item_view = _.find(self.dashboard.components, constraints);
          if (!item_view || item_view.enable == false || item_view.dashboard == false) {
            return;
          }*/
          var description = _.find(self.sensor_type, {
            code: v.type,
            id: v.id
          });
          if (!description) return
          var desc = description.value + ':' + v.value + description.unit + ' ';
          if (v.type === 10) {
            //if ($scope.isAdmin)
            //  result += desc
          } else if (v.type === 26) {
             result += description.value + ':' + utils.getWindDirectionName(v.value) + "(" + v.value + "°)" + ' ';
          } 
          else if(v.type === 29 || v.type === 30 || v.type === 34 || v.type === 35 || v.type === 36){
          } 
          else if(v.type === 1 && (self.user.service_type == 'watek' || self.user.service_type == 'btfs')){
          }
          else {
            result += desc
          }
        } else {
          result = "측정값 없음"
        }
      })
      return "<a href='" + self.getDeviceUrl(row) + "'>" + result + "</a>";
    },  
    wateringFormatter(value, row, index) {
      var self = this;
      var threshold = row.threshold;
      var water_level = _.find(threshold, {
        sensor_type: 19
      });

      if(row.service_type == 'sis'){
        var result = '-'
        if (water_level && _.get(row, 'water_level', -1) > -1) {
          if (row.water_level >= water_level.min && row.water_level < water_level.max) {
            var element = '<button id="waterBtn_{0}" class="btn btn-danger btn-sm" >부족</button>';
            result = element.format(row.guid);
          } else {
            var element = '<button id="wateringBtn_{0}" class="btn btn-success btn-sm" :disabled>{1}L</button>';
            // var element = '<button id="waterBtn_{0}" class="btn btn-success btn-sm" >{1}L</button>';
            result = element.format(row.guid, row.water_level);
          }
        }
        return result;
      }
      else if(row.service_type == 'sis.valve'){
        var element = '<button id="ballvalveBtn_{0}" class="btn btn-secondary btn-sm" :disabled>닫 힘</button>';
        var result = element.format(row.guid);
        this.$store.dispatch('rest/find', {model:'powering_timers'})
          .then((res) => {
            var datas = res;
            for(var i = 0; i < datas.length ; i++){
              if(datas[i].device_guid === row.guid){ 
                var name = 'ballvalveBtn_{0}'.format(row.guid);
                var button = document.getElementById(name);
                button.className = 'btn btn-success btn-sm';
                button.textContent = '열 림';
                //return '<button class="btn btn-success btn-sm" :disabled>ON</button>';
              }
            }            
          })
          return result;
      }
    },  
    dateFormatter(value, row, index) {
      if (!_.get(row, "empty", false)) {
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      } else {
        return "-"
      }
    },
    dateFormatter2(value, row, index) {
      if (_.get(row, "empty", false))
        return "-";
      var date_string = moment(value).format('YYYY-MM-DD HH:mm:ss');
      if (row.status !== 0 || new Date(value).getTime() <= new Date(row.sensor_time).getTime())
        return date_string;
      else
        return '<span style="color: red;">' + date_string + '</span>'
    },
    nutrientFormatter(value, row, index) {
      var threshold = row.threshold;
      var nutrient_level = _.find(threshold, {
        sensor_type: 19
      });

      var result = ''
      if (nutrient_level && _.get(row, 'nutrient_level', -1) > -1) {
        if (row.nutrient_level >= nutrient_level.min && row.nutrient_level < nutrient_level.max) {
          var element = '<button id="nutrientBtn_{0}" class="btn btn-danger btn-sm" >부족</button>';
          result = element.format(row.guid);
        } else {
          var element = '<button id="nutrientBtn_{0}" class="btn btn-success btn-sm" disabled="disabled">{1}L</button>';
          result = element.format(row.guid, row.nutrient_level);
        }
      }
      return result;
    },
    onClickTab(index) {
      var active_site_index = _.get(this.user, "active_site_index", -1);
      if (active_site_index != index) {
        this.user.active_site_index = index;
        this.user.active_site_name = this.siteTables[index].title;
        utils.setStorage("userInfo", this.userInfo);
      }
      if(index > this.site_tab_limit)
        this.site_name = this.user.active_site_name;
        return this.getDeviceList(index)
        .then((siteTable) => {
          return Promise.resolve(siteTable)
        })
    },
    onEvent(event) {
      var site_guid = event.site;
      var active_site_index = _.get(this.user, "active_site_index", -1);
      var index = _.indexOf(this.user.active_site_guids, site_guid);
      if (active_site_index !== index) {
        $('[href="#site_tab' + index + '"]').tab('show');
        this.onClickTab(index).then(res => {
          try {
            this.setMarkerImage(event)
          } catch (e) {
            // 
          }
        })
      } else {
        this.setMarkerImage(event)
      }

    },
    onClickRow(field, value, row) {
      this.$refs.googleMap.setCenter(field)
    },
    setMarkerImage(event) {
      this.$refs.googleMap.setMarkerImage(event)
    },
    btnTrigger(siteTable) {
      var self = this;
      siteTable.data.forEach((el, i) => {
        var types = [{type:'water', value:100}, {type:'nutrient', value:50}]
        types.forEach(elem => {
          var {type, value} = elem;

          var name = '{0}Btn_{1}'.format(type, el.guid);
          var elem = document.getElementById(name);
          if (!elem) return;

          elem.addEventListener("click", function(e) {
            var data = {};
            data[type+'_level'] = value
            loopback
              .upsertWithWhere('devices', {guid:el.guid}, data)
              .then(res => {
                // console.log('{0} reset : {1}'.format(type, JSON.stringify(res)))
                var button = $('#{0}Btn_{1}'.format(type, el.guid));
                button.hide();
                // $('#dataTable' + el.site_index).bootstrapTable('resetView');
                self.$emit('change', siteTable)
                elem.textContent = '100L';
                elem.className = 'btn btn-success btn-sm';
                elem.disabled = 'disabled';
                button.show();
              })
              .catch(err => {
                console.log('{0} reset err:'.format(type, err.toString()))
              })

          }, false);
        })
      })
    },
    getDashboard() {
      var self = this;
      var filter = {
        where: {
          user_guid: this.user.guid
        }
      }
      this.$store.dispatch('rest/find', {model:'dashboards',filter:filter})
        .then((res) => {
          self.dashboard = _.first(res);
          self.getDeviceList(self.active_site_index)
        })
    },
    getDeviceList(index, limit, skip) {
      var self = this;
      if (typeof limit === 'undefined') limit = 50
      if (typeof skip === 'undefined') skip = 0

      var default_site_guid = _.get(this.user, "default_site_guid");
      var active_site_index = _.get(this.user, "active_site_index", 0)
      var data = {
        limit: limit,
        skip: skip,
        site_guid: _.isEmpty(this.user.active_site_guids) ? this.user.site_guids : this.user.active_site_guids,
        active_site_index: active_site_index
      }
      return loopback
        .method('sites', 'getDeviceList', data)
        .then(res => {
          var has_watering = false;
          var has_nutrient = false;
          // console.log('Device::getDeviceList: ', JSON.stringify(res,null,2))
          self.makeTable(_.map(res, 'site_guid'))
          self.user.active_site_guids = []
          res.forEach(function(d, i) {
            var rows = [];
            d.data.forEach(function(e) {
              var interval = CronParser.parseExpression(e.upload_cron)
              var data = {
                site: d.Site.name,
                site_guid: d.Site.guid,
                site_index: i,
                center_device_guid: d.Site.center_device_guid,
                latitude: e.latitude,
                longitude: e.longitude,
                name: e.name,
                service_type: e.service_type,
                address: e.address,
                sensor: e.sensor,
                empty: e.sensor.empty,
                sensor_time: e.sensor.created_at,
                prev_time: new Date(interval.prev()),
                guid: e.guid,
                threshold: e.threshold,
                images: e.images,
                status: e.status
              }
              if (_.has(e, 'powering_cron') && _.get(e, 'water_level', -1) !== -1) {
                data['water_level'] = e.water_level
                has_watering = true;
              }
              if (_.has(e, 'nutrient_cron') && _.get(e, 'nutrient_level', -1) !== -1) {
                data['nutrient_level'] = e.nutrient_level
                has_nutrient = true;
              }
              rows.push(data)
            });
            var active_site_index = _.get(self.user, "active_site_index", -1);
            if (active_site_index != -1 && self.active_site_index != active_site_index) {
              if (active_site_index < res.length)
                self.active_site_index = active_site_index;
            } else {
              if (default_site_guid == d.site_guid) {
                self.active_site_index = i;
              }
            }

            if (_.indexOf(self.user.active_site_guids, d.site_guid) === -1) {
              self.user.active_site_guids.push(d.site_guid);
            }
            self.siteTables[i].title = d.Site.name;
            self.siteTables[i].data = rows;
            self.siteTables[i].device_count = rows.length;
            _.find(self.siteTables[i].columns, {
              field: 'watering'
            }).visible = has_watering;
            _.find(self.siteTables[i].columns, {
              field: 'nutrient'
            }).visible = has_nutrient;
          });
          var siteTable = self.siteTables[self.active_site_index]
          self.user.active_site_name = siteTable.title;
          utils.setStorage("userInfo", self.userInfo);
          if (!_.isUndefined(self.$refs.googleMap))
            self.$refs.googleMap.update(siteTable);

          // notify to parent : change
          self.$emit('change', siteTable)
          if (_.find(siteTable.columns, {field: 'watering'}).visible ||
              _.find(siteTable.columns, {field: 'nutrient'}).visible) {
            setTimeout(function() {
              self.btnTrigger(siteTable);
            },100)
          }
          return Promise.resolve(siteTable)
        })
    } // getDeviceList
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}

.text-box {
  position: absolute;
  height: 80%;
  color: white;
  text-align: center;
  width: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 30px;
}
.dataNumber {
  margin-top: auto;
}
.containerBox {
  position: relative;
  display: inline-block;
}

/* .carousel-indicators {
  position: absolute;
  right: ((100% - $carousel-caption-width) / 2);
  bottom: -25px;
  left: ((100% - $carousel-caption-width) / 2);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 0px;
}
.carousel-inner {
  width: 100%;
  max-height: 256px !important;
  background-color: silver !important;
}
.carousel-caption {
  position: absolute;
  right: ((100% - $carousel-caption-width) / 2);
  bottom: -10px;
  left: ((100% - $carousel-caption-width) / 2);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 0px;
  color: $carousel-caption-color;
  text-align: center;
} */
</style>
