<template>
  <CRow>
    <CCol col="12" xl="12" v-if="$is_mobile">
      <table id="statTable" class="table table-striped table-bordered table-hover" data-toggle="table">
        <thead>
          <tr>
            <th colspan="4" class="text-center">{{headerTitles[0]}}</th>
          </tr>
          <tr>
            <th class="text-center" v-for="(name, index) in subTitles" v-if="index>=0&&index<4">{{name}}</th>
          </tr>
        </thead>
        <tbody>
          <td class="text-center" v-for="(item, index) in data" v-if="index>=0&&index<4">
            <div class="containerBox">
              <img class="img-responsive" :src="findImage(item.key)" width="30pt" height="30pt"/>
              <div class="text-box"><h5 class="dataNumber">{{item.val}}</h5></div>
            </div>
          </td>
        </tbody>
      </table>
    </CCol>

    <CCol col="12" xl="12" v-else>
      <table id="statTable" class="table table-striped table-bordered table-hover" data-toggle="table">
        <thead>
          <tr>
            <th colspan="4" class="text-center" v-for="name in headerTitles">{{name}}</th>
          </tr>
          <tr>
            <th class="text-center" v-for="name in subTitles">{{name}}</th>
          </tr>
        </thead>
        <tbody>
          <td class="text-center" v-for="item in data"  @click="onClick(item)">
            <div class="containerBox">
              <img class="img-responsive" :src="findImage(item.key)" width="30pt" height="30pt"/>
              <div class="text-box"><h5 class="dataNumber">{{item.val}}</h5></div>
            </div>
          </td>
        </tbody>
      </table>
    </CCol>
  </CRow>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm';
import utils from '@/services/utils';
import { IMAGES } from "@/constants/constant"

import {
  SITE_TABLE_OPTIOINS, SITE_TABLE_COLUMS,
  DEVICE_TABLE_OPTIOINS, DEVICE_TABLE_COLUMS
} from "@/constants/siteSettings";

export default {
  name: 'Summation',
  components: {
  },
  created: function() {

  },
  data () {
    return {
      headerTitles: [
        '토양수분',
        //'초미세먼지',
        '배터리'
      ],
      subTitles: [
        '정상', '과다', '주의', '부족',
        //'좋음', '보통', '나쁨', '매우',
        '좋음', '보통', '주의', '경고'
      ],
      data: [
        { key: 'HN', val: 1 }, { key: 'HE', val: 0 }, { key: 'HC', val: 0 }, { key: 'HL', val: 0 },
        //{ key: 'PG', val: 1 }, { key: 'PN', val: 0 }, { key: 'PC', val: 0 }, { key: 'PW', val: 0 },
        { key: 'BG', val: 1 }, { key: 'BN', val: 0 }, { key: 'BC', val: 0 }, { key: 'BW', val: 0 },
      ]
    }
  },
  computed: {
  },
  methods: {
    findImage(key) {
      return IMAGES[key].name
    },
    onClick(item) {
      this.$emit('click', item)
    },
    update(count, events) {
      this.data.forEach(elem => elem.val = 0);
      //var pms_total = 0;

      events.forEach(row => {
        var key = 'BN';
        switch (row.sensor_type) {
          case 1:
            key = row.level == 1 ? 'BN' : row.level == 2 ? 'BC' : 'BW';
            break;
          case 2:
          case 12:
            key = row.level == 1 ? 'HE' : row.level == 2 ? 'HC' : 'HL';
            break;
          case 28:
            //key = row.level == 1 ? 'PN' : row.level == 2 ? 'PC' : 'PW'; pms_total += 1
            break;
        }
        var item = _.find(this.data, { key: key })
        item.val += 1
      })
      var item = _.find(this.data, { key : 'HN' })
      item.val = count - (_.find(this.data, {key:'HE'}).val + _.find(this.data, {key:'HC'}).val + _.find(this.data, {key:'HL'}).val)
      item = _.find(this.data, { key : 'BG' })
      item.val = count - (_.find(this.data, {key:'BN'}).val + _.find(this.data, {key:'BC'}).val + _.find(this.data, {key:'BW'}).val)
      //item = _.find(this.data, { key : 'PG' })
      //item.val = pms_total - (_.find(this.data, {key:'PN'}).val + _.find(this.data, {key:'PC'}).val + _.find(this.data, {key:'PW'}).val)
    }
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
.containerBox {
  position: relative;
  display: inline-block;
}

.text-box {
  position: absolute;
  height: 80%;
  color: white;
  text-align: center;
  width: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 30px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: 30px;
  margin: auto;
  padding: auto;
}

.dataNumber {
  margin-top: auto;
}

</style>