<template>
  <div>
    <CCard>
      <CCardHeader v-if="device_guid!==undefined">
        <strong>이벤트 목록</strong>
        <div class="card-header-actions">
          <CLink href="#" class="card-header-action btn-close" v-on:click="onCloseEvent()" :disabled="selected_row == null">
            <CIcon class="mb-1" name="cil-x-circle"/>
          </CLink>
        </div>
      </CCardHeader>
      <CCardBody v-if="device_guid!==undefined">
        <CRow>
          <CCol col="12" xl="12">
            <v-table id="eventTable"
              :columns="eventTable.columns" 
              :data="eventTable.data" 
              :options="eventTable.options" 
              @on-check="onCheckSite" 
              @on-uncheck="onUncheckSite"
              @on-click-row="onClickRow"
            ></v-table>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardBody class="pt-0" v-else>
        <div id="toolbar" class="pt-2">
          <strong class="pl-1 float-left mr-2">이벤트 목록</strong>
          <CLink href="#" class="card-header-action btn-close float-left" v-on:click="onCloseEvent()" :disabled="selected_row == null">
            <CIcon class="mb-1" name="cil-x-circle"/>
          </CLink>
        </div>
        <v-table id="eventTable"
          data-toolbar="#toolbar" 
          :columns="eventTable.columns" 
          :data="eventTable.data" 
          :options="eventTable.options" 
          @on-check="onCheckSite" 
          @on-uncheck="onUncheckSite"
          @on-click-row="onClickRow"
        ></v-table>
      </CCardBody>

    </CCard>

    <Confirm
        ref="confirmDialog"
        @hide="hideModal"
    />

    <Notify ref="notifyDialog"/>

  </div>

</template>

<script>
import loopback from '@/services/loopback';
import Summation from '@/views/dashboard/Summation'
import Device from '@/views/dashboard/Device'
import { EVENT_TABLE_OPTIOINS, EVENT_TABLE_COLUMS } from "@/constants/eventSettings"
import { IMAGES } from "@/constants/constant"
import EventBus from '@/services/EventBus'
import utils from '@/services/utils'

export default {
  name: 'Event',
  components: {
  },
  props: {
    device_guid : {
      type: String,
      default: undefined
    },
    prefix: {
      type: String,
      default: ''
    },
    event_height: {
      type: Number,
      default: -1
    }
  },
  created: function() {
    this.user = this.$store.state.auth.user_info.user;
    this.sites = this.$store.state.auth.user_info.sites

    var userInfo = utils.getStorage('userInfo')

    this.eventTable.options.clickToSelect = true;
    this.eventTable.options.singleSelect = true;
    _.find(this.eventTable.columns, { field: 'device_address' }).visible = false;
    _.find(this.eventTable.columns, { field: 'created_at' }).formatter = utils.dateFormatter;
    _.find(this.eventTable.columns, { field: 'is_closed' }).formatter = this.closeFormatter;
    _.find(this.eventTable.columns, { field: 'level' }).formatter = this.imageFormatter2;
    _.find(this.eventTable.columns, { field: 'site' }).formatter = this.siteFormatter;
    _.find(this.eventTable.columns, { field: 'device_name' }).formatter = this.nameFormatter;
    _.find(this.eventTable.columns, { field: 'sensor_type' }).formatter = this.sensorTypeFormatter;

    this.getEventList()
    EventBus.$on('reload', this.reloadListener);
    EventBus.$on('alarmData', this.onEvent)
    if (this.event_height != -1) {
      this.eventTable.options.height = this.event_height
    }
  },
  beforeDestroy(){
    EventBus.$off('alarmData', this.onEvent)
    EventBus.$off('reload', this.reloadListener)
  },
  data () {
    var table_options = _.cloneDeep(EVENT_TABLE_OPTIOINS);
    if (this.device_guid === undefined) {
      table_options.search = true;
    }
    return {
      selected_row: null,
      eventTable: {
        options: table_options,
        columns: _.cloneDeep(EVENT_TABLE_COLUMS),
        data: [],
      }
    }
  },
  methods: {
    reloadListener() {
      console.log('Event::reloadListener')
      this.getEventList();
    },
    nameFormatter(value, row, index) {
      return "<a href='#/{0}device/{1}'>{2}</a>".format(this.prefix, row.device_guid, row.device_name);
    },
    getSiteName(site_guid) {
      var site = this.sites.filter(function(e) {
        return e.guid == site_guid;
      })[0];
      return _.get(site, "name", "");
    },
    siteFormatter(value, row, index) {
      return "<a href='#/{0}sites/{1}'>{2}</a>".format(this.prefix, value, this.getSiteName(value));
    },
    closeFormatter(value, row, index) {
      return value == true ? 'Y' : 'N';
    },
    imageFormatter2(value, row, index) {
      var imgsrc;
      Object.keys(IMAGES).some(function(key, i) {
        if ((row.sensor_type % 64) === IMAGES[key].type && row.level === IMAGES[key].level) {
          imgsrc = IMAGES[key].name;
          return true;
        }
      });
      if(imgsrc == undefined)
          imgsrc = "img/humidity-lack.png";

      return '<img src=' + imgsrc + ' height=' + IMAGES.size.height + 'width=' + IMAGES.size.width + ' / > '
    },
    sensorTypeFormatter(value, row, index) {
      return utils.getSensorTypeName(row.sensor_type, row.sensor_id, true);
    },
    onCheckSite(row, $element) {
      this.selected_row = row;
    },
    onUncheckSite(row, $element) {
      this.selected_row = null;
    },
    hideModal(params) {
      if (_.isUndefined(params)) {
        return; // cancel command
      }
      switch (params.name) {
        case 'Confirm':
          if (params.which === 'delete' && params.data === 'confirm') {
            this.removeEvent(this.selected_row)
          }
          break;
        default:
          break
      }
    },
    onCloseEvent() {
      // console.log('Event::onCloseEvent selected_row:', JSON.stringify(this.selected_row,null,2))
      if (this.selected_row === null) return;

      let message = '이벤트 : ' + this.selected_row.device_name + ' 알림을 종료 하시겠습니까?'
      this.$refs.confirmDialog.show(message, '이벤트 종료', undefined, 'delete');
    },
    onClickRow(field, value, row) {
      this.$emit('click', field, value, row)
    },
    getTable() {
      return this.eventTable;
    },
    onEvent(message) {
      if (_.isUndefined(message.datas))
        return;

      var eventTable = $('#eventTable');
      message.datas.forEach(function(element) {
        var data = element.data;
        switch (element.subtype) {
          case 'add': {
            if (!_.isUndefined(data.Device)) {
              data["device_name"] = data.Device.name;
              data["device_address"] = data.Device.address;
              data["site_guid"] = data.Device.site_guid;
            }
            eventTable.bootstrapTable('prepend', data);
            break;
          }
          case 'delete': {
            eventTable.bootstrapTable('remove', {
              field: 'guid',
              values: [data.guid]
            });
            break;
          }
          case 'update': {
            eventTable.bootstrapTable('updateByUniqueId', {
              id: data.guid,
              row: data
            });
            break;
          }
        }
      })
      self.$emit('update', self.eventTable.data)
    },
    removeEvent(event) {
      var self = this;
      loopback
        .upsertWithWhere('/events', {guid: event.guid}, {is_closed: true})
        .then((res) => {
          let message = "{0} : '{1}\' 알림을 종료했습니다.".format(self.selected_row.device_name, res.description);
          this.$refs.notifyDialog.show(message, 2000);
          // console.log('Event::removeEvent : ', message)
          $('#eventTable').bootstrapTable('remove', {
            field: 'guid',
            values: [event.guid]
          });
        })
        .catch((err) => {
          console.log('removeEvent error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
    getEventList() {
      var self = this
      var data = {
        is_closed: 0,
        device_guid: this.device_guid
      }
      // console.log('Event::getEventList data:', JSON.stringify(data))
      this.$store.dispatch('rest/method', {model:'events', method:'getAlarmList', data:data})
        .then((res) => {
          self.eventTable.data = [];
          res.forEach(function(row, index) {
            self.eventTable.data.push({
              created_at: row.created_at,
              description: row.description,
              service_type: row.service_type,
              sensor_type: row.sensor_type,
              sensor_id: row.sensor_id,
              is_closed: row.is_closed,
              level: row.level,
              device_guid: row.device_guid,
              device_name: _.get(row, "device.name"),
              device_address: _.get(row, "device.address"),
              site: _.get(row, "device.site_guid"),
              guid: row.guid,
            });
          })
          self.$emit('update', self.eventTable.data)
        })
        .catch((err) => {
          console.log('queryEvent event: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })

    }
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  /* padding: 0.01rem; */
  padding: 0.50rem;
}
</style>